export default {
  async daily(params, config = {}) {
    const query = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    return await this.$get({
      url: `${this.$baseurl}/admin/statistics/daily?${query}`,
      headers: this.headers,
      ...config,
    });
  },
};
